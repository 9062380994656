import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/common";
import SEO from "../components/Seo"
import { GatsbyImage, getImage,  } from "gatsby-plugin-image"


const TagPageTemplate = ({ data, context }) => {

  const tags = data.tags.edges
    .map(e => e.node)

  const tag = tags[0].title
    
  const posts = tags[0].post
      
  const postsWithTag = Array.from(posts).length

  const title = `${tag} tag page - ${postsWithTag} post${
    postsWithTag === 1 ? "" : "s"
  } tagged with '${tag}'`



  return (
    <Layout>
      <SEO title={title} description={`${title} on Xaqt Blog`} canonical />

      <header className="pt-24 lg:pt-32 pb-4 lg:pb-6">
        <div className="container max-w-2xl text-center">
          <h1 className="font-semibold text-secondary-1">{tag}</h1>
          <p className="text-secondary-1 text-xl">{title}</p>
        </div>
      </header>

      <section className="sm:pt-10 pt-20">
        <div className="container mx-auto relative pb-10">
          <div className="flex flex-wrap -mx-4">
            {posts.map(i => {
              const postImage = getImage(i.image)
              return (
                <a 
                  href={`/blog/${i.slug}`}
                  className="px-4 w-full md:w-1/2 lg:w-1/4 my-6 hover:opacity-80">
                  <div className="w-full rounded-lg shadow-lg relative overflow-hidden aspect-w-16 aspect-h-9">
                    <div className="absolute inset-0">
                      <GatsbyImage 
                        className={`w-full h-full`} 
                        image={postImage} 
                        alt={i.image.title}
                      /></div>
                  </div> 
                  <p className="text-accent font-medium mt-4 mb-3">{i.heading}</p>
                  <p className="text-sm font-bold hidden text-grey">{i.createdAt}</p>
                </a>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default TagPageTemplate

export const TagPageQuery = graphql`
  query TagPagesQuery($id: String!) {
    
    tags: allContentfulTag(
      filter: {
        id: { eq: $id }
      }
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          id
          slug
          title
          post {
            id
            heading
            date(formatString: "MMMM DD yyyy")
            tags {
              slug
              title
              id
            }
            createdAt(formatString: "MMMM DD yyyy")
            slug
            image {
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
